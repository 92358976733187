import request from '@/utils/request'

// 查询管理员产品列表
export function findSystemProducts(params) {
  return request({
    url: `/system/products`,
    method: 'get',
    params: params
  })
}

// 查询管理员产品列表
export function findSystemProductList(params) {
  return request({
    url: `/system/products/list`,
    method: 'get',
    params: params
  })
}

// 查询租户产品列表
export function findTenantProducts(params) {
  return request({
    url: `/tenant/products`,
    method: 'get',
    params: params
  })
}

// 查询产品排序数据
export function findTenantSortProducts() {
  return request({
    url: `/tenant/products/sort`,
    method: 'get'
  })
}

// 查询产品详情
export function findProductInfo(id) {
  return request({
    url: `/products/${id}`,
    method: 'get'
  })
}

// 创建租户产品
export function createTenantProduct(data) {
  return request({
    url: `/tenant/products`,
    method: 'post',
    data
  })
}

// 创建管理员产品
export function createSystemProduct(data) {
  return request({
    url: `/system/products`,
    method: 'post',
    data
  })
}

// 更新租户产品有效性
export function updateTenantProductEffective(id, data) {
  return request({
    url: `/tenant/products/${id}/effective`,
    method: 'patch',
    data
  })
}

// 更新管理员产品有效性
export function updateSystemProductEffective(id, data) {
  return request({
    url: `/system/products/${id}/effective`,
    method: 'patch',
    data
  })
}

// 更新管理员产品
export function updateSystemProduct(id, data) {
  return request({
    url: `/system/products/${id}`,
    method: 'put',
    data
  })
}

// 更新租户产品
export function updateTenantProduct(id, data) {
  return request({
    url: `/tenant/products/${id}`,
    method: 'put',
    data
  })
}

// 删除管理员产品
export function deleteSystemProduct(id) {
  return request({
    url: `/system/products/${id}`,
    method: 'delete'
  })
}

// 查询租户产品选项
export function findTenantProductsOptions(params) {
  return request({
    url: `/tenant/products/options`,
    method: 'get',
    params: params
  })
}

// 删除代理商产品
export function deleteTenantProduct(id, data) {
  return request({
    url: `/tenant/products/${id}`,
    method: 'delete',
    data
  })
}
