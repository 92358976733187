<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="申请删除"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="删除原因">
          <a-input
            v-decorator="['reason', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入删除原因' },
                { max: 200, message: '最多200个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="第一审核人">
          <a-select
            v-decorator="['first_checker_id', {
              initialValue: financeDefaultId,
              rules: [{ required:true,type: 'number',message: '请选择财务' }],
            }]"
          >
            <a-select-option
              v-for="financier in financeOptions"
              :key="financier.id"
              :value="financier.id"
              :disabled="!financier.effective"
            >
              {{ financier.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="第二审核人">
          <a-select
            v-decorator="['second_checker_id', {
              initialValue: administrativeDefaultId,
              rules: [{ required:true,type: 'number',message: '请选择行政' }]
            }]"
          >
            <a-select-option
              v-for="administrator in administrativeOptions"
              :key="administrator.id"
              :value="administrator.id"
              :disabled="!administrator.effective"
            >
              {{ administrator.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="第三审核人">
          <a-select
            v-decorator="['third_checker_id', {
              initialValue: managerDefaultId,
              rules: [{ required:true,type: 'number',message: '请选择总经理' }]
            }]"
          >
            <a-select-option
              v-for="manager in managerOptions"
              :key="manager.id"
              :value="manager.id"
              :disabled="!manager.effective"
            >
              {{ manager.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { deleteTenantProduct, updateTenantProductEffective } from '@/api/product'
import {
  findTenantAdministrativeOptions,
  findTenantFinanceOptions,
  findTenantManagerOptions
} from '@/api/user'
import { deleteTenantPackage } from '@/api/package_management'

export default {
  name: 'DeleteTenantPackage',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'tenant_package_delete' }),
      financeOptions: [],
      administrativeOptions: [],
      managerOptions: [],
      financeDefaultId: '', // 审核默认id
      administrativeDefaultId: '', // 审核默认id
      managerDefaultId: '', // 审核默认id
      submitting: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchAllOptions()
  },
  methods: {
    // 加载下拉框选项
    fetchAllOptions() {
      findTenantFinanceOptions().then((res) => {
        this.financeOptions = res.data
        if (this.financeOptions.length > 0) {
          this.financeDefaultId = this.financeOptions[0].id
        }
      })
      findTenantAdministrativeOptions().then((res) => {
        this.administrativeOptions = res.data
        if (this.administrativeOptions.length > 0) {
          this.administrativeDefaultId = this.administrativeOptions[0].id
        }
      })
      findTenantManagerOptions().then((res) => {
        this.managerOptions = res.data
        if (this.managerOptions.length > 0) {
          this.managerDefaultId = this.managerOptions[0].id
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const editData = {
            ...values
          }
          const _this = this
          this.$confirm({
            title: '确定进行删除吗？',
            content: '删除后无法恢复',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              deleteTenantPackage(_this.record.id, editData).then((res) => {
                if (res.code === 0) {
                  // 关闭模态框
                  _this.isShow = false
                  // 告知父组件已完成
                  _this.$emit('completed')
                }
                _this.submitting = false
              })
            }
          })
        }
      })
    }
  }
}
</script>
